import ruCountries from 'world_countries_lists/data/ru/countries.json'
import enCountries from 'world_countries_lists/data/en/countries.json'
import frCountries from 'world_countries_lists/data/fr/countries.json'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

type UseCountriesType = {
  id: number
  name: string
  alpha2: string
  alpha3: string
}

export const useCountries = (): UseCountriesType[] => {
  const { i18n } = useTranslation()

  return useMemo(() => {
    switch (i18n.language) {
      case 'ru':
        return ruCountries.map((item) => {
          if (item.alpha2 === 'by') {
            return {
              id: 112, name: 'Беларусь', alpha2: 'by', alpha3: 'blr',
            }
          }
          return item
        })
      case 'en':
        return enCountries
      case 'fr':
        return frCountries
      default:
        return enCountries
    }
  }, [i18n.language])
}
